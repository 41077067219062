import React from "react"
import { motion } from "framer-motion"
import { useMitt } from "react-mitt"
import Button from "_components/buttons/button_parallax"
import SecondaryButton from "_components/buttons/button_underline"
import styled from "styled-components"
import {
  color_primButton,
  color_textLink,
} from "_src/assets/data/global_colors"

const Buttons = (props) => {
  const { emitter } = useMitt()

  return (
    <motion.div style={{ x: props.buttonsX, y: props.buttonsY }}>
      <Wrapper
        initial={{ y: 35, opacity: 0, rotate: 0.01 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 1.6, duration: 2, ease: "easeOut" }}
      >
        <Button
          color={color_primButton}
          shadowColor="black"
          copy="Enter Session"
          destination="/session"
          height={60}
          width={180}
        />
        <SecondButton
          onClick={function () {
            emitter.emit("pageChange", { data: 1, currentScreen: 0 })
          }}
        >
          <SecondaryButton
            copy="Learn more!"
            textColor={color_textLink}
            underlineColor={color_primButton}
          />
        </SecondButton>
      </Wrapper>
    </motion.div>
  )
}

const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media only screen and (max-width: 400px) {
    flex-direction: column;
    justify-content: space-between;
    height: 100px;
  }
`

const SecondButton = styled.div`
  margin-left: 20px;
  @media only screen and (max-width: 400px) {
    margin-left: 0px;
  }
`

export default Buttons
