import React, { useRef } from "react"
import { motion, useAnimation } from "framer-motion"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

const spring = {
  type: "spring",
  stiffness: 330,
  damping: 55,
  mass: 7,
  restDelta: 0.001,
  restSpeed: 0.001,
}

const Illustration = (props) => {
  const anim = useAnimation()
  const ref = useRef()

  return (
    <IllustrationWrapper
      initial={{ y: 15, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ delay: 1, duration: 2 }}
    >
      <motion.div
        style={{ x: props.imageX, y: props.imageY, rotateZ: props.imageRZ }}
      >
        <motion.div
          ref={ref}
          style={{ userSelect: "none" }}
          transition={spring}
          animate={anim}
          whileHover={{ scale: 1.1, rotateZ: 2 }}
          onMouseMove={function (e) {
            const { clientY } = e
            anim.start({
              y: clientY / 100,
            })
          }}
        >
          <StaticImage
            src="index_therapistSitting.png"
            alt="Therapist using ActiveEMDR"
          />
        </motion.div>
      </motion.div>
    </IllustrationWrapper>
  )
}

const IllustrationWrapper = styled(motion.div)`
  width: 100%;
`

export default Illustration
