import React from "react"
import { motion } from "framer-motion"
import { Paragraph } from "_data/global_styles"
import PageHeadline from "_components/misc/pageHeadline"
import {
  color_paragraph,
  color_paragraphHighlite,
} from "_src/assets/data/global_colors"

const Headline = (props) => {
  return (
    <motion.div
      initial={{ y: 30, rotate: 0.01, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ delay: 0.5, duration: 2, ease: "easeOut" }}
    >
      <motion.div style={{ x: props.titleX, y: props.titleY }}>
        <PageHeadline copy="Empowering EMDR therapists with better tools to serve their clients." />
      </motion.div>
    </motion.div>
  )
}

const Body = (props) => {
  return (
    <motion.div
      initial={{ y: 35, rotate: 0.01, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ delay: 1, duration: 2, ease: "easeOut" }}
      style={{ marginLeft: ".5vw", color: "#303c6c" }}
    >
      <motion.div style={{ x: props.paraX, y: props.paraY }}>
        <Paragraph style={{ color: color_paragraph }}>
          We've created an easy-to-use web based application for therapists to
          reliably offer{" "}
          <span
            style={{
              color: color_paragraphHighlite,
              fontWeight: "800",
              textDecoration: "none",
            }}
          >
            Bilateral / Dual Attention Stimulation
          </span>{" "}
           in person or via telehealth. Also known as virtual or remote EMDR,
          our tools are free and available to any and all trained EMDR
          therapists and their clients.{" "}
        </Paragraph>
      </motion.div>
    </motion.div>
  )
}

const Copy = (props) => {
  return (
    <div>
      <Headline {...props} />
      <Body {...props} />
    </div>
  )
}

export default Copy
