import React from "react"
import "_data/normalize.css"
import "@fontsource/montserrat/800.css"
import "@fontsource/montserrat/700.css"
import "@fontsource/montserrat/600.css"
import "@fontsource/montserrat/500.css"
import Seo from "_components/indexScreens/_intro/intro_seo"
import Content from "_components/indexScreens/_intro/intro_content"
import { ScreensLayout } from "_components/indexScreens/common/screensLayout"

const screenBackgroundColor = "white"
const screenSwipeBackgroundColor = "#f4f4f4"
const initialUpperContentMargin = 35
const initialLowerContentMargin = 175
const currentScreen = 0
const showFooter = false
const previousPageAllowed = false
const nextPageAllowed = true

const Index = () => {
  return (
    <>
      <Seo />
      {ScreensLayout(
        Content,
        screenSwipeBackgroundColor,
        screenBackgroundColor,
        initialUpperContentMargin,
        initialLowerContentMargin,
        showFooter,
        nextPageAllowed,
        previousPageAllowed,
        currentScreen
      )}
    </>
  )
}

export default Index
