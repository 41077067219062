import React, { useEffect } from "react"
import { useMitt } from "react-mitt"
import { useSpring, useTransform } from "framer-motion"
import Buttons from "./intro_buttons"
import Copy from "./intro_content-copy"
import Illustration from "./intro_illustration"
import styled from "styled-components"

const IndexContent = () => {
  const { emitter } = useMitt()
  let mouseX = useSpring(0, { restDelta: 0.001, restSpeed: 0.001 })
  let mouseY = useSpring(0, { restDelta: 0.001, restSpeed: 0.001 })
  let titleX = useTransform(mouseX, (value) => value / 180)
  let titleY = useTransform(mouseY, (value) => value / 140)
  let paraX = useTransform(mouseX, (value) => value / 220)
  let paraY = useTransform(mouseY, (value) => value / 180)
  let buttonsX = useTransform(mouseX, (value) => value / 300)
  let buttonsY = useTransform(mouseY, (value) => value / 300)
  let imageX = useTransform(mouseX, (value) => value / 300)
  let imageY = useTransform(mouseY, (value) => value / 300)
  let imageRZ = useTransform(mouseX, (value) => value / 2000)

  useEffect(() => {
    emitter.on("mouseX", (event) => {
      const offsetX = event.data - window.innerWidth / 2
      mouseX.set(offsetX)
    })

    emitter.on("mouseY", (event) => {
      const offsetY = event.data - window.innerHeight / 2
      mouseY.set(offsetY)
    })
  }, [emitter, mouseX, mouseY])

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Content style={{ userSelect: "none" }}>
        <Primary>
          <Copy titleX={titleX} titleY={titleY} paraX={paraX} paraY={paraY} />
          <Buttons test="this" buttonsX={buttonsX} buttonsY={buttonsY} />
        </Primary>
        <Secondary>
          <Illustration imageX={imageX} imageY={imageY} imageRZ={imageRZ} />
        </Secondary>
      </Content>
    </div>
  )
}

const Content = styled.div`
  display: flex;
  flex-direction: row;
  z-index: 2;
  width: 80%;
  max-width: 1000px;
  @media only screen and (max-width: 770px) {
    width: 85%;
  }
`

const Primary = styled.div`
  order: 1;
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media only screen and (max-width: 750px) {
    width: 100%;
  }
`

const Secondary = styled.div`
  order: 2;
  width: 25%;
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 750px) {
    display: none;
  }
`

export default IndexContent
